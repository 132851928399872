import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Search({ size, color, title }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title && <title>{title}</title>}
      <BaseIconPathStyle
        d="M11 3.25C9.07718 3.25 7.23311 4.01384 5.87348 5.37348C4.51384 6.73311 3.75 8.57718 3.75 10.5C3.75 12.4228 4.51384 14.2669 5.87348 15.6265C7.23311 16.9862 9.07718 17.75 11 17.75C12.6712 17.75 14.2828 17.173 15.5687 16.1294L19.9697 20.5303C20.2626 20.8232 20.7374 20.8232 21.0303 20.5303C21.3232 20.2374 21.3232 19.7626 21.0303 19.4697L16.6294 15.0687C17.673 13.7828 18.25 12.1712 18.25 10.5C18.25 8.57718 17.4862 6.73311 16.1265 5.37348C14.7669 4.01384 12.9228 3.25 11 3.25ZM6.93414 6.43414C8.01247 5.3558 9.47501 4.75 11 4.75C12.525 4.75 13.9875 5.3558 15.0659 6.43414C16.1442 7.51247 16.75 8.97501 16.75 10.5C16.75 12.025 16.1442 13.4875 15.0659 14.5659C13.9875 15.6442 12.525 16.25 11 16.25C9.47501 16.25 8.01247 15.6442 6.93414 14.5659C5.8558 13.4875 5.25 12.025 5.25 10.5C5.25 8.97501 5.8558 7.51247 6.93414 6.43414Z"
        strokeRound
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
      />
    </BaseSvgStyle>
  );
}

export default Search;
